<template>
    <div class="text-center" v-if="!is_data_ready">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="tab-pane fade show active text-center" v-else-if="matchCommentary.length === 0">
        <p>{{$t('football.match-detail.No Commentary found')}}</p>
    </div>
    <div class="tab-pane active" id="commentary" v-else>
        <div class="commentary_list_wrap">
            <ul id="commentary_list_ul">
                <li class="ftb_details_commentary_section clearfix" v-for="commentary in matchCommentary" :key="commentary.id">
                    <div class="ftb_details_commentary_box">
                        <div class="ftb_details_commentary_pic" v-if="commentary.mediaImage">
                          <span>
                              <img :src="commentary.mediaImage" alt="MediaImage" loading="lazy" width="1232" height="1232">
                          </span>
                        </div>
                        <div class="ftb_details_commentary_desc">
                            <div class="ftb_details_commentary_desc_row">
                                <div class="ftb_details_commentary_desc_personal">
                                    <div class="ftb_commentary_time_status_col">
                                        <a class="pic_area" target="_blank" href="javascript:;" v-if="commentary.commentText == 'twitter'">
                                            <img :src="require('@/assets/images/football/twitterX.png')" alt="Twitter" loading="lazy">
                                        </a>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'goal'"><img :src="require('@/assets/images/football/4.png')" alt="Goal" title="Goal" loading="lazy"></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'sub'"><img :src="require('@/assets/images/football/sub.webp')" alt="Sub" title="Sub" loading="lazy"></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'gk'"><img :src="require('@/assets/images/football/gk.png')" alt="Goal Keeper" title="Goal Keeper" loading="lazy"></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'corner'"><img :src="require('@/assets/images/football/corner.webp')" alt="corner" title="corner" loading="lazy"></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'offside'"><img :src="require('@/assets/images/football/offside.webp')" alt="offside" title="offside" loading="lazy"></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'card' && commentary.comment.includes('yellow')">
                                            <img :src="require('@/assets/images/football/114.png')" alt="card" title="card" loading="lazy" v-if="commentary.comment.includes('yellow')">
                                            <img :src="require('@/assets/images/football/115.png')" alt="card" title="card" loading="lazy" v-else-if="commentary.comment.includes('red')">
                                            <img :src="require('@/assets/images/football/5.png')" alt="card" title="card" loading="lazy" v-else>
                                        </span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'ft'"><h3>FT</h3></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == 'ht'"><h3>HT</h3></span>
                                        <span class="pic_area" v-else-if="commentary.commentText == '1st' || commentary.commentText == '2nd' || commentary.commentText == 'kickoff'"><img :src="require('@/assets/images/football/start.png')" alt="Start" title="Start" loading="lazy"></span>
                                        <span class="pic_area" v-else><img :src="require('@/assets/images/football/soccer.webp')" :alt="commentary.commentText" :title="commentary.commentText" loading="lazy"></span>
                                        
                                        <b>{{commentary.minute}}{{ (commentary.duration != 0) ? "+"+commentary.duration : ""}}'</b>
                                    </div>
                                    <div class="ftb_commentary_title_details_col">
                                        <!-- <a :href="commentary.authorPageUrl" class="commentary_desc_logo" target="_blank"v-if="commentary.authorPageUrl">
                                            <img :src="commentary.authorProfileImage" alt="twitter" loading="lazy">
                                        </a> -->
                                        <p v-html="commentary.comment"></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    const STORAGE_KEY = '_fb_commentary_like';
    export default {
        name: "MatchDetailCommentary",
        metaInfo() {
            let ss = this.$store.state.siteSetting;
             var replacements = {};
            if(this.$store.state.fbMatchDetailResponse.data && this.$store.state.fbMatchDetailResponse.data.competitors){
                var replacements = {
                    '%HOME_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, true).name,
                    '%AWAY_TEAM%': this.$filterTeam(this.$store.state.fbMatchDetailResponse.data.competitors, false).name,
                    '%DATE%': this.$route.params.date,
                };
            }

            let title = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_commentary_meta_title,replacements));
            let description = this.$getSafe(() => this.$replacePlaceHolders(ss.football_match_commentary_meta_description,replacements));
            let keywords = this.$getSafe(() => ss.football_match_commentary_meta_keywords);

            return {
                title: title,
                description: description,
                meta: this.$getMetaTags(title, description, keywords),
                link: [
                    {rel: 'canonical', href: this.$toFullPath(this.$route.fullPath)}],
            };
        },
        data() {
            return {
                matchCommentary: {},
                postIds: []
            };
        },
        mounted() {
            this.postIds = this.$store.getters.fbLikeCommentary;
            this.getCommentary();
        },
        serverPrefetch() {
            return this.getCommentary();
        },
        methods: {
            getCommentary() {
                this.is_data_ready = false;

                return this.$axios
                    .get(this.$FB_MATCH_COMMENTARY_API.replace(':match', this.$route.params.matchId))
                    .then(response => {
                        this.matchCommentary = response.data.commentary;
                        this.is_data_ready = true;

                    })
                    .catch(error => console.log(error))
            },
            decodeHtml(html) {
                if (isBrowser) {
                    var txt = document.createElement("textarea");
                    txt.innerHTML = html;
                    return txt.value;
                }
            },
            likePost(post_id) {

                var check = this.findPost(this.postIds, post_id, true, 'dislike');
                if (check == 0) {
                    var params = {post_id: post_id, type: 'like', subtype: 'add'};
                    this.$axios
                        .get(this.$FB_COMMENTARY_LIKES_API, {params})
                        .then(response => {
                            this.setPostLikesDislike(post_id, response.data);
                            this.postIds.push({
                                'post_id': post_id,
                                'like': 1,
                                'dislike': 2,
                            });
                            this.$store.commit('addFbLikeCommentary', JSON.stringify(this.postIds))
                        })
                        .catch(error => console.log(error))
                } else {
                    if (check == 1) {
                        var params = {post_id: post_id, type: 'like', subtype: 'add_remove'};
                        this.$axios
                            .get(this.$FB_COMMENTARY_LIKES_API, {params})
                            .then(response => {
                                this.setPostLikesDislike(post_id, response.data);
                                this.postIds.push({
                                    'post_id': post_id,
                                    'like': 1,
                                    'dislike': 2,
                                });
                                this.$store.commit('addFbLikeCommentary', JSON.stringify(this.postIds))

                            })
                            .catch(error => console.log(error))
                    } else if (check == 2) {
                        var params = {post_id: post_id, type: 'like', subtype: 'remove'};
                        this.$axios
                            .get(this.$FB_COMMENTARY_LIKES_API, {params})
                            .then(response => {
                                this.setPostLikesDislike(post_id, response.data);
                            })
                            .catch(error => console.log(error));
                    }
                }
            },
            dislikePost(post_id) {
                var check = this.findPost(this.postIds, post_id, true, 'like');
                if (check == 0) {
                    var params = {post_id: post_id, type: 'dislike', subtype: 'add'};
                    this.$axios
                        .get(this.$FB_COMMENTARY_LIKES_API, {params})
                        .then(response => {
                            this.setPostLikesDislike(post_id, response.data);
                            this.postIds.push({
                                'post_id': post_id,
                                'like': 2,
                                'dislike': 1,
                            });
                            this.$store.commit('addFbLikeCommentary', JSON.stringify(this.postIds))

                        })
                        .catch(error => console.log(error))
                } else {
                    if (check == 1) {
                        var params = {post_id: post_id, type: 'dislike', subtype: 'add_remove'};
                        this.$axios
                            .get(this.$FB_COMMENTARY_LIKES_API, {params})
                            .then(response => {
                                this.setPostLikesDislike(post_id, response.data);
                                this.postIds.push({
                                    'post_id': post_id,
                                    'like': 2,
                                    'dislike': 1,
                                });
                                this.$store.commit('addFbLikeCommentary', JSON.stringify(this.postIds))

                            })
                            .catch(error => console.log(error))
                    } else if (check == 2) {
                        var params = {post_id: post_id, type: 'dislike', subtype: 'remove'};
                        this.$axios
                            .get(this.$FB_COMMENTARY_LIKES_API, {params})
                            .then(response => {
                                this.setPostLikesDislike(post_id, response.data);
                            })
                            .catch(error => console.log(error));
                    }
                }
            },
            setPostLikesDislike(post_id, data) {
                var BreakException = {};
                try {
                    this.matchCommentary.forEach(function (obj) {
                        if (obj.id == post_id) {
                            obj.likes = data.result.likes;
                            obj.dislikes = data.result.dislikes;
                            throw BreakException;
                        }
                    })
                } catch (e) {
                    if (e !== BreakException) throw e;
                }
            },
            findPost(Ids, post_id, check, reaction) {
                var flag = 0;
                if (reaction == 'like') {
                    flag = 2;
                } else if (reaction == 'dislike') {
                    flag = 2;
                }
                for (var i = 0, len = Ids.length; i < len; i++) {
                    if (check) {
                        if (Ids[i].post_id === post_id) {
                            var r_flag = Ids[i][reaction];
                            Ids.splice(i, 1);
                            this.$store.commit('addFbLikeCommentary', JSON.stringify(Ids))

                            return r_flag;
                        }
                    } else {
                        if (Ids[i].post_id === post_id && flag == Ids[i][reaction]) {
                            return true;
                        }
                    }
                }
                return false;
            }
        }
    };
</script>
<style>
    .ftb_details_commentary_pic {
        margin-bottom: 20px;
    }
    .ftb_details_commentary_desc .ftb_details_commentary_desc_row .ftb_details_commentary_desc_personal .ftb_commentary_title_details_col p span a {
        color: #007bff;
    }
    .ftb_commentary_title_details_col p span {
        padding: 0 3px;
        white-space: nowrap;
    }
    #commentary{background: #ffffff;}
</style>
